import './Reviews.css';

function Reviews() {
  return (
    <section className="reviews__general">
      <div className="reviews__single">
        <p className="reviews__text">
          Моему сыну очень нравятся занятия шахматами, особенно ждёт встречи с
          Анатолием Николаевичем! Миша очень скромный,на другие занятия идёт,
          потому-что нужно,а в шахматную школу Анатолия Терёхина бежит и
          чувствует себя там свободно. Все преподаватели добрые и
          отзывчивые.Спасибо Вам большое за хорошее настроение и развитие нашего
          сына!
        </p>
        <p className="reviews__author"> Анна Макарова</p>
        <p className="reviews__text">
          Уважаемые родители и дети,желающие заниматься шахматами,добрый день!
          Если вы еще в раздумьях чем занять своего ребенка-шахматная школа
          Анатолия Терехина-лучшее предложение для желающих интеллектуально
          развить своего ребенка. Мой сын с большим удовольствием посещает школу
          5 год, всегда ждет начала занятий и новых встреч с любимыми
          преподавателями.В школе созданы хорошие условия для занятий,
          пед.состав всегда внимателен к детям и родителям,спасибо им большое!
          Желаем новых побед, талантливых учеников и благодарных родителей!
        </p>
        <p className="reviews__author"> Чернышева Ольга </p>
        <p className="reviews__text">
          Отличная школа. Кроме серьезных спортивных достижений тренеров и их
          воспитанников еще выделю любовь тренеров к детям. Дети здесь именно
          воспитанники. Поэтому результаты хорошие и не ограничиваются
          шахматами. Навыки помогают в учебе. Да и в целом полезны. Шутка ли,
          дети только шахматных задач по несколько тысяч уже нарешали за пару
          лет. Желаем школе удачи !
        </p>
        <p className="reviews__author"> Бакировы</p>
        <p className="reviews__text">
          Добрый день! Мой сын, Иван Колобов, третий год успешно обучается в
          вашей замечательной шахматной школе, у не менее замечатильных
          педагогов! Успехи в шахматах а также в развитии логического мышления
          очевидны! Благодаря этим навыкам мы успешно поступили в 1 класс
          гимназии №17 без дополнительных курсов, школ развития и прочих других
          мест. Мы желаем замечательному коллективу и школе процветания и
          успехов во всех начинаниях! Мы с Вами до конца))))
        </p>
        <p className="reviews__author"> Яна Колобова</p>
      </div>
    </section>
  );
}

export default Reviews;
